import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  CommonService
} from '../common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  contact: FormGroup;
  requested: boolean = false;
  constructor(private cs: CommonService, private fb: FormBuilder) {
    this.contact = this.fb.group({
      'first_name': ["", Validators.compose([Validators.required])],
      'last_name': ["", Validators.compose([Validators.required])],
      'email': ["", Validators.compose([Validators.required, Validators.email])],
      'message': ["", Validators.compose([Validators.required])]
    })
  }

  ngOnInit() {}
  submitContact() {
    this.requested = true;

    const {
      first_name,
      last_name,
      email,
      message
    } = this.contact.value;
    let send = {
      firstName: first_name,
      lastName: last_name,
      email,
      message
    }
    this.cs.httpRequest('post', 'contactus', send).subscribe(
      (res) => {
        this.cs.showSuccessMsg('We Will Reach you ASAP!', 'Thank you');
        this.requested = false;
        this.contact.reset();
      },
      (err) => {
        this.requested = false;
        this.cs.handleError(err)
      }
    )
  }

}
