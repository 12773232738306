import { RouterModule, Routes } from '@angular/router';
import { Injectable, NgModule } from '@angular/core';

const appRoutes: Routes = [
  {
    path: 'home', 
    loadChildren:()=>import('./home/home.module').then((m)=>m.HomeModule)
  },
  {
    path: '',
    redirectTo:'home',
    pathMatch:"full"
  },
  {
    path: 'joinus',
    loadChildren:()=>import('./join/join.module').then((m)=>m.JoinModule)
  },
  {
    path: 'register',
    loadChildren:()=>import('./register/register.module').then((m)=>m.RegisterModule)
  },
  { 
    path: 'new-artist',
    redirectTo:'joinus'
  },
  {
    path: 'artist/:id', 
    loadChildren:()=>import('./artist/artist.module').then((m)=>m.ArtistModule),
  },
  {
    path: 'artist', 
    redirectTo:'home',
    pathMatch:'full'
  },
  {
    path: '**', 
    loadChildren:()=>import('./error404/error404.module').then((m)=>m.Error404Module)
  }

]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes,{
      scrollPositionRestoration: 'enabled',
    })
  ],
  exports: [
    RouterModule
  ]
})

@Injectable({
  providedIn: 'root'
})

export class RoutingService {

  constructor() { }
}
