import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  newsletter:FormGroup;
  requested=false;
  constructor(private fb:FormBuilder,private cs:CommonService) { 
    this.newsletter = this.fb.group({
      'name': ['', Validators.required],
      'email':['',Validators.compose([Validators.required,Validators.email])],
    })
  }

  submitNewsLetter(){
    this.requested = true
    const { name,email } = this.newsletter.value
    this.cs.httpRequest('post','subscribe',{name,email}).subscribe(
      (res)=>{
        this.cs.showSuccessMsg('Thanks For Subscribing to our Newsletter','Newsletters Subscribed')
        this.newsletter.reset();
        this.requested= false;
      },
      (err)=>{
        this.requested= false;
        this.cs.handleError(err);
      }
    );
  }

  ngOnInit() {
  }

}
