import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  Title
} from '@angular/platform-browser';
import iziToast from "izitoast"

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  baseUrl = 'http://15.206.78.151:3000/api/v1/';
  header: HttpHeaders;
  constructor(private http: HttpClient, private titleService: Title) {
    this.initIziToast()
  }

  getHeader() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  httpRequest(req: string, url: string, data ? : object) {
    if (req == 'get') {
      return this.http.get(this.getUrl(url), {
        headers: this.getHeader()
      });
    } else if (req == "post") {
      return this.http.post(this.getUrl(url), data, {
        headers: this.getHeader()
      });
    } else if (req == "put") {
      return this.http.put(this.getUrl(url), data, {
        headers: this.getHeader()
      });
    } else if (req == 'del' || req == "delete" || req == 'dlt') {
      let request = {
        body: data,
        headers: this.getHeader()
      }
      return this.http.delete(this.getUrl(url), request)
    }
  }

  getUrl(url: string) {
    return this.baseUrl + url;
  }

  setTitleValue(val: string) {
    this.titleService.setTitle('Wallpaper Fever :: ' + val);
  }

  toggleNavBar(e: any) {
    e.preventDefault;
    var body = document.getElementById('body')
    if (body.clientWidth > 1000) {
      body.classList.toggle('sidebar-mini');
    } else {
      body.classList.toggle('sidebar-gone');
      body.classList.toggle('sidebar-show');
    }
  }

  initIziToast() {
    iziToast.settings({
      timeout: 3000,
      pauseOnHover: true,
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
    });
  }

  showSuccessMsg(msg ? : string, title ? : string, position ? ) {
    iziToast.success({
      position: position || 'bottomRight',
      title: title,
      overlay: position ? true : false,
      message: msg
    });
  }

  showInfoMsg(msg ? : string, title ? : string, position ? ) {
    iziToast.info({
      title: title,
      position: position || 'bottomRight',
      overlay: position ? true : false,
      message: msg
    });
  }

  showWarningMsg(msg ? : string, title ? : string, position ? ) {
    iziToast.warning({
      title: title,
      position: position || 'bottomRight',
      overlay: position ? true : false,
      message: msg
    });
  }

  showErrorMsg(msg ? : string, title ? : string, position ? ) {
    iziToast.error({
      title: title,
      position: position || 'bottomRight',
      overlay: position ? true : false,
      message: msg
    });
  }

  showSomethingWentWrong(msg ? : string, title ? : string, position ? ) {
    iziToast.error({
      title: 'Oops!',
      position: position || 'bottomRight',
      overlay: position ? true : false,
      message: 'Something Went Wrong!',
    });
  }

  handleError(err) {
    if (err.status == 0) {
      return this.showErrorMsg('Unkown Error', "Please Check Your Internet Connection")
    } else if (err.status == 500 || err.status == 501 || err.status == 502 || err.status == 503 || err.status == 504 || err.status == 505) {
      return this.showErrorMsg('Server Side Error', "Something Went Wrong on Server")
    } else if (err.status == 404) {
      return this.showErrorMsg('Not Found', "Data Missing")
    } else if (err.status == 403) {
      return this.showErrorMsg('Not Allowed', "Not Authorized to access this Page")
    } else {
      this.showErrorMsg('Ooops!', err.error.error_description);
    }
  }

  imageUpload(send) {
    return this.http.post(this.getUrl('uploads'), send)
  }

  handleAddress(place: any) {
    let output = {
      state: null,
      country:null,
			city: null,
      address: null,
      lat:null,
      lng:null
		}
		return new Promise(async (resultP, error) => {
			let country: any = (place.address_components.find((item) => item.types.indexOf("country") >= 0));
			let city: any = (place.address_components.find((item) => item.types.indexOf('locality') >= 0));
			let state: any = (place.address_components.find((item) => item.types.indexOf('administrative_area_level_1') >= 0));
			output.address = place.formatted_address
			if (state) {
				output.state = state.long_name
			}
			if (country) {
				output.country = country.long_name
			}
			if (city) {
				output.city = city.long_name
      }
      let geo = place.geometry.location
      output.lat = geo.lat()
      output.lng = geo.lng()
			resultP(output)
		});
  }


	numberInputOnly(e: any) {
		var key = e.which;
		if (key === 8 || key === 9 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
			return true;
		}
		e.preventDefault();
		return false;
	}

}
